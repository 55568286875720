import React from "react";
import { Card, Container } from "react-bootstrap";

const Page404 = () => {
  return (
    <div className="bg-img">
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "100vh" }}
      >
        <Card className="text-center card-large">
          <Card.Body className="req-border left-form-bg">
            <h4 className="text-green my-4">Invalid Request</h4>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default Page404;
