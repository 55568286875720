import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import Page404 from "./pages/Page404";
import Page500 from "./pages/Page500";
import ThemeWrapper from "./ThemeWrapper";
import { DEFAULT_THEME } from "./constant";
const DefaultLayout = lazy(() => import("./layout/DefaultLayout"));

const App = () => (
    <Router>
      <Suspense fallback={<Spinner animation="border" style={{color: DEFAULT_THEME.colorCode}} />}>
        <ThemeWrapper>
          <Routes>
            <Route path="/*" element={<DefaultLayout />} />
            <Route path="/*" element={<Page404 />} />
            <Route path="/500" element={<Page500 />} />
          </Routes>
        </ThemeWrapper>
      </Suspense>
    </Router>
  );

export default App;
