import React, { useState } from 'react'
import { DEFAULT_THEME } from './constant';
import { ThemeContext } from './context';

const ThemeWrapper = ({ children }) => {
  const [themeValue, setThemeValue] = useState({...DEFAULT_THEME});

  return (
    <ThemeContext.Provider
      value={{
        themeValue,
        setThemeValue,
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeWrapper