export const APP_DOMAIN = process.env.REACT_APP_DOMAIN;
export const API_URL = process.env.REACT_APP_BASE_URL;
export const ASSET_URL = process.env.REACT_APP_ASSET_URL;
export const APP_NAME = process.env.REACT_APP_NAME;
export const KEY = 'DU16HY06MP985458'; // process.env.REACT_APP_KEY;
export const IV = '0123456789abcdef'; // process.env.REACT_APP_IV;

export const STEP_LIST = {
  default: 'DEFAULT',
  imps: 'IMPS',
  upi: 'UPI',
  usdt: 'USDT'
};

export const SITE_DETAIL = {
  amount: 100,
  utr: null,
  appId: null,
  paymentMode: null,
  orderId: null,
  step: STEP_LIST.default,
  paymentId: null,
  transactionId: null,
  loading: true,
  availableMethod: ''
};

export const PAYMENT_MODE = {
  imps: 'IMPS',
  upi: 'UPI',
  usdt: 'USDT'
};

export const DEFAULT_BANK = {
  accountNumber: '122222',
  ifscCode: 'SBIN00000',
  accountHolder: 'test',
  bankName: 'SBI Bank'
};

export const DEFAULT_UPI = {
  vpa: 'test@gmail.com',
  utr: '121345678915'
};

export const COPY_TEXT = 'copy';
export const COPIED_TEXT = 'Copied !';
export const VIDEO_URL_UPI = 'https://youtu.be/nphcLdHzbLs';
export const VIDEO_URL_IMPS = 'https://www.youtube.com/watch?v=4r9Xd07BMfE';
export const VIDEO_URL_UPI_INTENT =
  'https://www.youtube.com/watch?v=symEzrTfFQE';

export const DEFAULT_THEME = {
  bannerImg: '/assets/img/theme1/bro.png',
  colorCode: '#20C2AE'
};
